import { SimpleGrid, Box, Text, Container } from '@chakra-ui/react'
import React from 'react'
import { useItemNumberProducts } from '../providers/ItemNumberProductsProvider';
import { Link } from 'react-router-dom';

export default function TotalSale() {

    const { data, thisUserData } = useItemNumberProducts();

    var valueNotZero = [];

    /* eslint-disable no-loop-func */
    for (var i of data) {
        if (thisUserData
            .filter((element) =>
                element['PTypM_id'].toString() == i['id'].toString())
            .length > 0) {
            valueNotZero.push(i);
        }
    }

    valueNotZero = valueNotZero.sort((a, b) => a['name'].localeCompare(b['name']));

    return (
        <Container maxW='8xl' alignItems='center' justifyContent='center'>
            <SimpleGrid columns={[4, 4, 8, 12]} spacing='2' p='2' my='2' alignItems='center' justifyContent='center'>
                {valueNotZero.map((value) => (
                    <Box
                        as={Link}
                        to={'/products'}
                        state={{
                            id: value['id'],
                            name: value['name'],
                            data: thisUserData.filter((element) => element['PTypM_id'].toString() == value['id'].toString()),
                            length: thisUserData.filter((element) => element['PTypM_id'].toString() == value['id'].toString()).length,
                        }}
                        aspectRatio={1 / 1}
                        display='flex'
                        flexDirection='column'
                        justifyContent='center'
                        alignItems='center'
                        key={value['id']}
                    >
                        <Box
                            p='2'
                            bg='primary.800'
                            rounded='50%'
                            aspectRatio={1 / 1}
                            display='flex'
                            justifyContent='center'
                            alignItems='center'
                            mb='1'
                        >
                            <Text
                                fontWeight='800'
                                color='#fefefe'
                                fontSize='2xl'
                            >
                                {thisUserData
                                    .filter((element) =>
                                        element['PTypM_id'].toString() == value['id'].toString())
                                    .length}
                            </Text>
                        </Box>
                        <Text
                            fontWeight='800'
                            fontSize='xs'
                            textAlign='center'
                        >
                            {value['name']}
                        </Text>
                    </Box>
                ))}
            </SimpleGrid>
        </Container>
    )
}
