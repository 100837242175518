import axios from "axios";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { url } from "../config";
import { useAuth } from "./AuthProvider";

const ItemNumberProductsContext = createContext();

const ItemNumberProductsProvider = ({ children }) => {

    const { user } = useAuth();
    // const data = [];
    const [data, setData] = useState([]);

    const [thisUserData, setThisUserData] = useState([]);
    const [thisUserNetSaleData, setThisUserNetSaleData] = useState([]);
    const [thisUserInStockData, setThisUserInStockData] = useState([]);
    const [thisUserMonthlySaleData, setThisUserMonthlySaleData] = useState([]);

    const [totalData, setTotalData] = useState([]);
    const [dropdownData, setDropdownData] = useState([]);

    const [loading, setLoading] = useState(false);

    async function get() {
        console.log('get EComService called');
        setLoading(true);

        try {
            var { data } = await axios.post(`${url}/api`,
                {
                    "PrdDate": "01-01-2000",
                    "url": "EComService_GetItemDetail"
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            );

            // console.log(data);

            let tempData = [];
            for (let d of data['response4']) {
                tempData.push({
                    'name': d['Category'],
                    'qty': d['Qty'],
                    'id': d['PTypM_id'],
                });
            }

            let tempTotalData = data['response1'];
            // let thisUserId = 'someUserId'; // Define thisUserId appropriately

            // console.log(tempTotalData);

            let tmpThisUserData = tempTotalData.filter(t => t['Bgm_LegMId'].toString() === user['Usr_id'].toString());

            let tempThisUserNetSaleData = tmpThisUserData.filter(t => t['Bgm_PurposeId'] > 0);

            let tempThisUserInStockData = tmpThisUserData.filter(t => t['Bgm_PurposeId'] === 0 || t['Bgm_PurposeId'] === null);

            let tempDropdownData = data['response5'];

            // select all data where bgm_FsoldDate is not null and of this month and year
            // tempTotalData = tempTotalData.filter(t => t['bgm_FsoldDate'] !== null && t['bgm_FsoldDate'].split('-')[1] === (new Date()).getMonth() + 1 && t['bgm_FsoldDate'].split('-')[0] === (new Date()).getFullYear());

            var dateParsedData = [];

            dateParsedData = tmpThisUserData.filter(t => t['bgm_FsoldDate'] !== null);

            // /Date(1732772627287)/
            // for each data in dateParsedData parse the date, it is in the format of /Date(1732772627287)/
            for (let d of dateParsedData) {
                let milliseconds = parseInt(d['bgm_FsoldDate'].toString().substring(6, 19));
                // console.log(milliseconds);
                d['bgm_FsoldDate'] = new Date(milliseconds);
            }

            // filter all dateParsedData which is of this month  and year
            dateParsedData = dateParsedData.filter(t => t['bgm_FsoldDate'].getMonth() === (new Date()).getMonth() && t['bgm_FsoldDate'].getFullYear() === (new Date()).getFullYear());

            console.log(dateParsedData);
            // console.log(tempThisUserNetSaleData);
            // console.log(tempThisUserInStockData);

            setThisUserMonthlySaleData(dateParsedData);
            setData(tempData);
            setDropdownData(tempDropdownData);
            setTotalData(tempTotalData);
            setThisUserData(tmpThisUserData);
            setThisUserNetSaleData(tempThisUserNetSaleData);
            setThisUserInStockData(tempThisUserInStockData);

        } catch (error) {
            return "err";
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        get();
    }, []);

    const contextValue = useMemo(
        () => ({
            get,
            loading,
            data,
            totalData,
            thisUserData,
            thisUserNetSaleData,
            thisUserInStockData,
            dropdownData,
            thisUserMonthlySaleData,
        }),
        [data, loading] // eslint-disable-line react-hooks/exhaustive-deps
    );

    return (
        <ItemNumberProductsContext.Provider value={contextValue}>{children}</ItemNumberProductsContext.Provider>
    );
};

export const useItemNumberProducts = () => {
    return useContext(ItemNumberProductsContext);
};

export default ItemNumberProductsProvider;