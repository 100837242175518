import React from 'react'
import {
  Card,
  Container,
  Text,
  VStack,
  SimpleGrid,
  CircularProgress,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom';
import { useItemNumberProducts } from '../../providers/ItemNumberProductsProvider'

export default function Dashboard() {

  const { thisUserNetSaleData, thisUserMonthlySaleData, thisUserData, thisUserInStockData, loading, get } = useItemNumberProducts();

  if (loading) {
    return <VStack p='3'>
      <div>Loading...</div>
      <CircularProgress isIndeterminate color="green"></CircularProgress>
    </VStack>
  }

  return (
    <Container p='4' maxW='4xl'>
      <VStack width='100%' spacing={4}>

        <SimpleGrid columns={[2, 2, 4, 4]} spacing='4' w='100%'>

          <Card as={Link} to={'/total-sale'} justifyContent='center' alignItems='center' p='3' w='100%' bg='primary.800' color='white'>
            <Text fontSize='2xl' fontWeight='bold' as='h2'>{thisUserData.length}</Text>
            <Text>Total Sale</Text>
          </Card>

          <Card as={Link} to={'/net-sale'} justifyContent='center' alignItems='center' p='3' w='100%' bg='primary.800' color='white'>
            <Text fontSize='2xl' fontWeight='bold' as='h2'>{thisUserNetSaleData.length}</Text>
            <Text>Net Sale</Text>
          </Card>

          <Card justifyContent='center' alignItems='center' p='3' w='100%' bg='primary.800' color='white'>
            <Text fontSize='2xl' fontWeight='bold' as='h2'>{thisUserMonthlySaleData.length}</Text>
            <Text>Monthly Sale</Text>
          </Card>

          <Card as={Link} to={'/in-stock'} justifyContent='center' alignItems='center' p='3' w='100%' bg='primary.800' color='white'>
            <Text fontSize='2xl' fontWeight='bold' as='h2'>{thisUserInStockData.length}</Text>
            <Text>In Stock</Text>
          </Card>

          <Card as={Link} to={'/buy-back'} justifyContent='center' alignItems='center' p='3' w='100%' bg='primary.800' color='white'>
            <Text fontSize='2xl' fontWeight='bold' as='h2'>{"-"}</Text>
            <Text>Buy Back</Text>
          </Card>

          <Card onClick={get} justifyContent='center' alignItems='center' p='3' w='100%' bg='primary.800' color='white'>
            <Text fontSize='2xl' fontWeight='bold' as='h2'>{"..."}</Text>
            <Text>Refresh</Text>
          </Card>

        </SimpleGrid>

      </VStack>
    </Container>
  )
}   
