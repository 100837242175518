import {
    Input,
    HStack,
    IconButton,
    Text,
    Container,
    VStack,
    Accordion,
    Box,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
} from '@chakra-ui/react';
import React, { useRef } from 'react'
import { useSearchItem } from '../providers/SearchItemProvider';
import { FaQrcode, FaSearch } from 'react-icons/fa';
import { image } from '../config';
import { useLocation } from 'react-router-dom';
import { useCart } from '../providers/CartProvider';
import { useItemNumberProducts } from '../providers/ItemNumberProductsProvider';
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { toast } from 'react-toastify';


export default function ScanPage() {

    const { search: searchParams } = useLocation();
    const { checkExists, insert, deleteItem } = useCart();
    const { thisUserData } = useItemNumberProducts();
    const { isOpen, onOpen, onClose } = useDisclosure()

    const {
        search,
        data,
        materialData,
        amount,
        error,
        notExists,
        searched,
        searching,
    } = useSearchItem();

    const searchItemRef = useRef();

    React.useEffect(() => {
        if (searchParams) {
            search(searchParams.split("item=")[1]);
            searchItemRef.current.value = searchParams.split("item=")[1];
        }
    }, [searchParams]);

    return (
        <Container maxW={['8xl', '8xl', '5xl', '4xl']} my='4'>
            <VStack spacing='4' alignItems='start'>
                <HStack w='100%' mt='4'>
                    <Input
                        required={true}
                        placeholder='Enter Item #'
                        ref={searchItemRef}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                search(searchItemRef.current.value);
                            }
                        }
                        }
                    />
                    <IconButton
                        onClick={() => search(searchItemRef.current.value)}
                        bg='primary.800'
                        color='white'
                        _hover={{ bg: 'primary.800' }}
                        icon={<FaSearch color='white' />}
                    />
                    <IconButton
                        onClick={() => {
                            onOpen();
                            // toast.error("Error launching camera Not Allowed !!");
                        }}
                        bg='primary.800'
                        color='white'
                        _hover={{ bg: 'primary.800' }}
                        icon={<FaQrcode color='white' />}
                    />


                    {/* <QrReader
                        style={{
                            height: 240,
                            width: 320,
                        }}
                        delay={300}
                        onScan={(scanResult)=> {
                            console.log(scanResult);
                        }}
                    /> */}

                </HStack>

                {searching && !error && !notExists && <h1>Searching...</h1>}

                {searched && notExists && <h1>Item not found</h1>}

                {searched && error && <h1>{error}</h1>}

                {searched && data && !searching && (
                    <>
                        <HStack>
                            <VStack w='60%' alignItems='start' justifyContent='space-between'>
                                <Text fontSize={['sm', 'sm', 'lg', 'lg']}>Item #: {data.Item_No}</Text>
                                <Text fontSize={['sm', 'sm', 'lg', 'lg']}>Style No: {data.stleNO}</Text>
                                <Text fontSize={['sm', 'sm', 'lg', 'lg']}>Category: {data.Category}</Text>
                                <Text fontSize={['sm', 'sm', 'lg', 'lg']}>Karat: {data.Metal_Purity}</Text>
                                <Text fontSize={['sm', 'sm', 'lg', 'lg']}>Price: {amount.FSP}</Text>
                            </VStack>

                            <HStack w='40%'>
                                <img src={`${image}/${data.stleNO}.jpg`} alt='Logo' />
                            </HStack>
                        </HStack>

                        <HStack w='100%'>
                            <Text fontSize={['sm', 'sm', 'lg', 'lg']}>Price (Incl Taxes) :
                                <span
                                    style={{
                                        backgroundColor: '#6a355b',
                                        color: '#fefefe',
                                        padding: '2px',
                                        borderRadius: '6px',
                                        marginLeft: '2px',
                                        textDecoration: 'line-through',
                                        'textDecorationThickness': '1px'
                                    }}
                                > {(amount.FSP * 1.03).toFixed(0)}</span>
                            </Text>
                            <Text fontSize={['sm', 'sm', 'lg', 'lg']}>
                                <span
                                    style={{
                                        backgroundColor: '#6a355b',
                                        color: '#fefefe',
                                        padding: '2px',
                                        borderRadius: '6px',
                                        marginLeft: '2px'
                                    }}
                                > {(amount.LABSALEFSPFSP * 1.03).toFixed(0)}</span>
                            </Text>

                        </HStack>

                        {checkExists(data.Item_No) ? (
                            <Button
                                colorScheme='red'
                                size='sm'
                                onClick={() => {
                                    try {
                                        deleteItem(data.Item_No);
                                    } catch (error) {
                                        toast.error("Error removing from cart. !")
                                    }
                                }}
                            >Remove from cart</Button>
                        ) : (
                            <Button
                                colorScheme='whatsapp'
                                size='sm'
                                onClick={() => {
                                    try {
                                        insert(thisUserData.filter(d => d.Item_No === data.Item_No)[0]);
                                    } catch (error) {
                                        toast.error("Error inserting to cart. !")
                                    }
                                }}
                            >Add To Cart</Button>
                        )}

                        <HStack w='100%' justifyContent='space-between'>
                            <Text fontWeight='700' fontSize={['md', 'md', 'lg', 'lg']}>Gross Wt : {data.Product_Weight}</Text>
                            <Text fontWeight='700' fontSize={['md', 'md', 'lg', 'lg']}>Net wt : {data.Metal_Weight}</Text>
                        </HStack>

                        <HStack w='100%' justifyContent='space-between'>
                            <Text fontWeight='700' fontSize={['md', 'md', 'lg', 'lg']}>DIA : {(data.diapcs)} / {(data.diawt)} Cts</Text>
                            <Text fontWeight='700' fontSize={['md', 'md', 'lg', 'lg']}>CS : {(data.cspc)} / {(data.cswt)} Cts</Text>
                        </HStack>

                        <Accordion
                            allowToggle
                            allowMultiple
                            w='100%'
                            border='0px'
                            outline='none'
                        >
                            <AccordionItem border='1px solid #6a355b;' w='100%'>
                                <AccordionButton w='100%' px='1' py='3'>
                                    <HStack w='100%' justifyContent='space-between'>
                                        <Text fontWeight='800'>Amount Details</Text>
                                        <AccordionIcon />
                                    </HStack>
                                </AccordionButton>

                                <AccordionPanel p='0'>
                                    <table style={{ width: '100%' }}>
                                        <thead>
                                            <tr>
                                                <Text as='th' fontSize={['sm', 'sm', 'lg', 'lg']}>Metal</Text>
                                                <Text as='th' fontSize={['sm', 'sm', 'lg', 'lg']}>Diamond</Text>
                                                <Text as='th' fontSize={['sm', 'sm', 'lg', 'lg']}>CS</Text>
                                                <Text as='th' fontSize={['sm', 'sm', 'lg', 'lg']}>Making</Text>
                                                <Text as='th' fontSize={['sm', 'sm', 'lg', 'lg']}>Total</Text>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{amount.GoldAmt}</td>
                                                <td>{amount.Stoneamt}</td>
                                                <td>{amount.CSamt}</td>
                                                <td>{amount.lbramt}</td>
                                                <td>{amount.FSP}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </AccordionPanel>

                            </AccordionItem>

                            <AccordionItem mt='2' border='1px solid #6a355b;' w='100%'>
                                <AccordionButton w='100%' px='1' py='3'>
                                    <HStack w='100%' justifyContent='space-between'>
                                        <Text fontWeight='800'>Material Details</Text>
                                        <AccordionIcon />
                                    </HStack>
                                </AccordionButton>

                                <AccordionPanel p='0'>
                                    <Box w='100%' overflow='scroll'>
                                        <table style={{ width: '100%' }}>
                                            <thead>
                                                <tr>
                                                    <Text as='th' fontSize={['sm', 'sm', 'lg', 'lg']}>Type</Text>
                                                    <Text as='th' fontSize={['sm', 'sm', 'lg', 'lg']}>Pcs</Text>
                                                    <Text as='th' fontSize={['sm', 'sm', 'lg', 'lg']}>Wt</Text>
                                                    <Text as='th' fontSize={['sm', 'sm', 'lg', 'lg']}>Rate</Text>
                                                    <Text as='th' fontSize={['sm', 'sm', 'lg', 'lg']}>Amt</Text>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {materialData.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{item.DIAMOND_SHAPE}</td>
                                                        <td>{item.DIAMONDS}</td>
                                                        <td>{item.DIAMOND_WEIGHT.toFixed(2)}</td>
                                                        <td>{item.Rate}</td>
                                                        <td>{item.Amount}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </Box>
                                </AccordionPanel>

                            </AccordionItem>


                        </Accordion>

                        {/* <Text fontWeight='800'>Amount Details</Text>
                        <table style={{ width: '100%' }}>
                            <thead>
                                <tr>
                                    <Text as='th' fontSize={['sm', 'sm', 'lg', 'lg']}>Metal</Text>
                                    <Text as='th' fontSize={['sm', 'sm', 'lg', 'lg']}>Diamond</Text>
                                    <Text as='th' fontSize={['sm', 'sm', 'lg', 'lg']}>CS</Text>
                                    <Text as='th' fontSize={['sm', 'sm', 'lg', 'lg']}>Making</Text>
                                    <Text as='th' fontSize={['sm', 'sm', 'lg', 'lg']}>Total</Text>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{amount.GoldAmt}</td>
                                    <td>{amount.Stoneamt}</td>
                                    <td>{amount.CSamt}</td>
                                    <td>{amount.lbramt}</td>
                                    <td>{amount.FSP}</td>
                                </tr>
                            </tbody>
                        </table> */}

                        {/* {materialData.length > 0 && (
                            <>
                                <Text fontWeight='800'>Material Details</Text>

                                <table style={{ width: '100%' }}>
                                    <thead>
                                        <tr>
                                            <Text as='th' fontSize={['sm', 'sm', 'lg', 'lg']}>Type</Text>
                                            <Text as='th' fontSize={['sm', 'sm', 'lg', 'lg']}>Pcs</Text>
                                            <Text as='th' fontSize={['sm', 'sm', 'lg', 'lg']}>Wt</Text>
                                            <Text as='th' fontSize={['sm', 'sm', 'lg', 'lg']}>Rate</Text>
                                            <Text as='th' fontSize={['sm', 'sm', 'lg', 'lg']}>Amt</Text>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {materialData.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.DIAMOND_SHAPE}</td>
                                                <td>{item.DIAMONDS}</td>
                                                <td>{item.DIAMOND_WEIGHT}</td>
                                                <td>{item.Rate}</td>
                                                <td>{item.Amount}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </>
                        )} */}
                    </>
                )}
            </VStack>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Scan Item</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <BarcodeScannerComponent
                            width={500}
                            height={500}
                            onUpdate={(err, result) => {
                                console.log(result);
                                console.log(err);
                                if (result) {
                                    toast.info(result.text);
                                    searchItemRef.current.value = result.text;
                                }
                                //   if (result) setData(result.text);
                                //   else setData("Not Found");

                                if (err.name === "NotAllowedError") {
                                    // Handle messaging in our app after the user chooses to not allow the camera permissions
                                    toast.error("Error launching camera Not Allowed !!");
                                }

                                if (err) {
                                    toast.error(err.message);
                                }
                            }}
                            onError={(err) => {
                                toast.error(err.message);
                            }}
                        />
                    </ModalBody>
                </ModalContent>
            </Modal>

        </Container >
    )
}
